import { TailSpin } from "react-loader-spinner";
const LoaderComp = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center'
        }}>
        <TailSpin
            height="15"
            width="15"
            color="#29166f"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
        />
        </div>
    );
};  
export default LoaderComp;