import React from 'react'

export const AboutDropDown = (props) => {
  return (
    <section className="pt-0">
    <div className="container">
   
        <div className="row wow fadeIn position-relative z-index-1" data-wow-delay="200ms">
            <div className="col-lg-12">
                <div id="accordion" className="accordion-style style1">
                {props.aboutDDData.map(function(data,index) {
                    return(
                    <div className="card mb-3" key={index}>
                        <div className="card-header" id={"headingOne"+data.id+""}>
                            <h5 className="mb-0">
                                {index==0?
                                <button className="btn btn-link " data-bs-toggle="collapse" 
                                data-bs-target={"#collapseOne"+data.id+""} aria-expanded="true" 
                                aria-controls={"#collapseOne"+data.id+""}>{data.Titile}
                                </button>:
                                 <button className="btn btn-link collapsed" data-bs-toggle="collapse" 
                                 data-bs-target={"#collapseOne"+data.id+""} aria-expanded="true" 
                                 aria-controls={"#collapseOne"+data.id+""}>{data.Titile}
                                 </button>
                                }
                            </h5>
                        </div>
                        {index==0?
                        <div id={"collapseOne"+data.id+""} className="collapse show" 
                        aria-labelledby={"headingOne"+data.id+""} data-bs-parent="#accordion">
                            <div className="card-body" dangerouslySetInnerHTML={{ __html: data.Description }}/>
                        </div>:
                         <div id={"collapseOne"+data.id+""} className="collapse " 
                         aria-labelledby={"headingOne"+data.id+""} data-bs-parent="#accordion">
                             <div className="card-body" dangerouslySetInnerHTML={{ __html: data.Description }}/>
                         </div>}
                    </div>
                    )})}
                    
                 </div>
            </div>
           
            
        </div>
    </div>
   {/* <img src="assets/img/content/shape6.png" className="position-absolute top-0 ani-left-right right-5 d-none d-md-block" alt="..."/>
    <img src="assets/img/content/shape1.png" className="position-absolute bottom-0 start-0 opacity1 ani-top-bottom" alt="..."/>
*/}
    </section>
  )
}

