import React ,{useEffect,useState}from 'react';
import { BreadCumb } from '../component/BreadCumb'
import { Content } from './News/Content'
import { listNewsEventData } from '../service/apiService';
import { useParams,useLocation } from "react-router-dom";
import { NewsData } from './News/NewsData';

export const NewsDetails = () => {
    let { newsData } = useParams(); 
    const location = useLocation()
  
  const [newsContact,setnewsContact]=useState([]); 
  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    })
  },[]);

 
  return (
   <>
     <BreadCumb bgImage={"url('../assets/img/banner/newsevents.jpg')"} 
     
     routeLink={"news"} mainRoute={"Home"} 
    route={"News"} title={"News"}/>
    <NewsData data={location.state.newsDetails}/>
   </>
  )
}
