import { ADD_ITEM,DELETE_ITEM } from "../Action/ActionTypes";


const initialState = {
  numOfItems: 0,
  lang:'en'
};

export  const cartReducer = (state = initialState, action) => {
   
  switch (action.type) {
    case ADD_ITEM:
      return {
        ...state,
        lang:action.payload.val
      };

    case DELETE_ITEM:
      return {
        ...state,
      };
    default:
      return state;
  }
};
