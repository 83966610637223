import React from 'react'
import { Lang } from '../component/Language/Lang';
import { config } from '../config/rest';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import LoaderComp from './Loader';
export const Ourproduct = (props) => {
    const { innerWidth: width, innerHeight: height } = window;
  return (
    <section>
        {width < 600 ?
            <div className="container">
                <div className="text-center mb-1-9 wow fadeIn title-style1" data-wow-delay="100ms">
                    <span className="fontBold">{Lang[props.langData.lang].headHomeOPrd}</span>
                </div>
                {props.prdData?<></>:<LoaderComp/>}
                <Carousel
                centerMode
                centerSlidePercentage={100}
                selectedItem={0}
                showArrows={false}
                dots={false}
            >
                {props.prdData.map(function(data,index) {
                    return(
                        <div  className="card-style8 m-3 m-lg-4 insCard" key={index}>
                            <div className="d-flex card1">
                        <div className="flex-shrink-0 icon-box">
                            <img src={config.IMG_URL+data.attributes.icon.data.attributes.url}
                             alt="..." style={{width:'65px',height:'65px'}}/>
                            
                        </div>
                        <div className="flex-grow-1 ms-3 ms-sm-4">
                            <h4 className="h5 mb-2">{data.attributes.title}</h4>
                            <p  className="mb-0 defaultfontandspacewith " dangerouslySetInnerHTML={{ __html: data.attributes.details }}></p>
                        </div>
                        <div className="go-corner" href="#">
                            <div className="go-arrow">
                              →
                            </div>
                          </div>
                    </div>
                        </div>
                )})}
                
                
            </Carousel>
                <div className="feature-carousel owl-theme owl-carousel ">
               
                </div>
            </div>:

<div className="container">
<div className="text-center mb-1-9 wow fadeIn title-style1" data-wow-delay="100ms">
    <span className="fontBold">{Lang[props.langData.lang].headHomeOPrd}</span>
</div>
{props.prdData?<></>:<LoaderComp/>}
<Carousel
centerMode
centerSlidePercentage={100 / 3}
selectedItem={0}
showArrows={false}
dots={false}
>
{props.prdData.map(function(data,index) {
    return(
        <div  className="card-style8 m-3 m-lg-4 insCard" key={index}>
            <div className="d-flex card1">
        <div className="flex-shrink-0 icon-box">
            <img src={config.IMG_URL+data.attributes.icon.data.attributes.url}
             alt="..." style={{width:'65px',height:'65px'}}/>
            
        </div>
        <div className="flex-grow-1 ms-3 ms-sm-4">
            <h4 className="h5 mb-2">{data.attributes.title}</h4>
            <p  className="mb-0 defaultfontandspacewith " dangerouslySetInnerHTML={{ __html: data.attributes.details }}></p>
        </div>
        <div className="go-corner" href="#">
            <div className="go-arrow">
              →
            </div>
          </div>
    </div>
        </div>
)})}


</Carousel>
<div className="feature-carousel owl-theme owl-carousel ">

</div>
</div>}
        </section>
  )
}
