import React from 'react'
import { config } from '../../config/rest'
import { AboutDropDown } from './AboutDropdown' 

export const Content = (props) => {
  return (
    <section className="pb-0">
       {props.abtData.map(function(data,index) {
        return (
    <div className="container" key={index}>
        <div className="row about-style2">
            <div className="col-lg-4 mb-1-9 mb-lg-0 wow fadeIn" data-wow-delay="200ms">
                <div className="pe-xl-1-9 mb-md-2-9 position-relative">
                    <div className="text-end about-img1 position-relative image-hover">
                        <img src={config.IMG_URL+data.attributes.mdimage.data.attributes.url} className="rounded" alt="..."/>
                        <p className="mb-1-9   text-secondary lh34"
        dangerouslySetInnerHTML={{ __html: data.attributes.chairmanDetails }}/>
                        
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-8 wow fadeIn" data-wow-delay="400ms">
                <div className="ps-xl-2-3">
                    <h2 className="h2 mb-1-6 oragneTxt">{props.comtitle}</h2>
                    <p className="mb-1-9   text-secondary lh34 " 
                    dangerouslySetInnerHTML={{ __html: data.attributes.abtDescripitoin }}/>
                    
                  
                    
                </div>
            </div>
        </div>

        <div className="row about-style2">
        <div className="col-lg-12 wow fadeIn" data-wow-delay="400ms">
       <div className="ps-xl-2-3">
       <div className="inner-title">
                                    <h2 className="h4 mb-0">{data.attributes.title_we_are_the_pioneer}</h2>
                                </div>
        <div className="defltlist" dangerouslySetInnerHTML={{ __html: data.attributes.desc_we_are_the_pioneer }}/>
                                

         </div>
        </div>
        </div>
        <div className="col-lg-12 wow fadeIn" data-wow-delay="400ms">
       <div className="ps-xl-2-3">
       <div className="inner-title"> <h2 className="h4 mb-0">{data.attributes.title_global_operation}</h2></div>
       <p className="mb-1-9   text-secondary lh34"
       dangerouslySetInnerHTML={{ __html: data.attributes.desc_global_operation }}/>
    </div>
    </div>

    <div className="col-lg-12 wow fadeIn" data-wow-delay="400ms">
       <div className="ps-xl-2-3">
       <div className="inner-title"> <h2 className="h4 mb-0">{data.attributes.title_oman_operation}</h2></div>
       <p className="mb-1-9   text-secondary lh34"
       dangerouslySetInnerHTML={{ __html: data.attributes.desc_oman_operation }}/>
     
    </div>
    </div>

    <div className="row about-style2">
           
            
            <div className="col-lg-8 wow fadeIn" data-wow-delay="400ms">
                <div className="ps-xl-2-3">
                <p className="mb-1-9   text-secondary lh34"
        dangerouslySetInnerHTML={{ __html: data.attributes.ceodetailsdata }}/>
                </div>
            </div>
            <div className="col-lg-4 mb-1-9 mb-lg-0 wow fadeIn" data-wow-delay="200ms">
                <div className="pe-xl-1-9 mb-md-2-9 position-relative">
                    <div className="text-end about-img1 position-relative image-hover">
                        <img src={config.IMG_URL+data.attributes.coimage.data.attributes.url} className="rounded" alt="..."/>
                       <div  dangerouslySetInnerHTML={{ __html: data.attributes.ceoDetails }}/>
                      
                    </div>
                </div>
            </div>
        </div>

   <div className="row about-style2">

<div id="accordion" className="accordion-style style1">
    <div className="card mb-3">
        <div className="card-header" id="headingOne">
            <h5 className="mb-0">
                <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    {data.attributes.Ourvisoin_title}</button></h5>
        </div>
        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
            <div className="card-body defltlist" dangerouslySetInnerHTML={{ __html: data.attributes.Ourvisoin_desc }}/>
           
        </div>
    </div>
    <div className="card mb-3">
        <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                {data.attributes.Ourmision_title}</button></h5>
        </div>
        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
            <div className="card-body defltlist"  
            dangerouslySetInnerHTML={{ __html: data.attributes.Ourmision_desc }}/>
           
        </div>
    </div>
    <div className="card mb-3">
        <div className="card-header" id="headingThree">
            <h5 className="mb-0">
                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    {data.attributes.Ourvalues_title}</button></h5>
        </div>
        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
            <div className="card-body defltlist"
            dangerouslySetInnerHTML={{ __html: data.attributes.Ourvalues_desc }}
            />
        </div>
    </div>
    <div className="card mb-3">
        <div className="card-header" id="headingFour">
            <h5 className="mb-0">
                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                {data.attributes.Ourcommitment_title}</button></h5>
        </div>
        <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-bs-parent="#accordion">
            <div className="card-body defltlist"
            dangerouslySetInnerHTML={{ __html: data.attributes.Ourcommitment_desc }}/>
        </div>
    </div>
    <div className="card mb-3">
        <div className="card-header" id="headingFive">
            <h5 className="mb-0">
                <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                {data.attributes.Ourculture_title}</button></h5>
        </div>
        <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-bs-parent="#accordion">
            <div className="card-body defltlist"
            dangerouslySetInnerHTML={{ __html: data.attributes.Ourculture_desc }}/>
        </div>
    </div>
    <div className="card mb-3">
        <div className="card-header" id="headingFiver">
            <h5 className="mb-0">
                <button className="btn btn-link collapsed" data-bs-toggle="collapse"
                 data-bs-target="#collapseFiver" aria-expanded="false" aria-controls="collapseFiver">
                    {data.attributes.standard_service_title}</button></h5>
        </div>
        <div id="collapseFiver" className="collapse" aria-labelledby="headingFiver" data-bs-parent="#accordion">
            <div className="card-body defltlist"
            dangerouslySetInnerHTML={{ __html: data.attributes.standard_service_desc }}/>
        </div>
    </div> 
</div>
                    
   </div>
   <div className="d-inline-block p-3 border border-width-2 border-primary-color bottom-10 right-10 ani-move
    position-absolute rounded-circle d-none d-sm-block"></div>
    <br/>
     <div className="inner-title">
                                    <h2 className="h4 mb-0">{data.attributes.ContentDropdownSectionTitle}</h2>
                                </div>
<AboutDropDown aboutDDData={data.attributes.ContentDropdownSection} />
    </div>
    
        )})}

  
</section>
  )
}
