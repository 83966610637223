import React from 'react'

export const Marketing = (props) => {
  return ( 
    <div className="row about-style mb-lg-6">
                <div classNameName="col-lg-12 wow fadeIn table-responsive" data-wow-delay="400ms">
                <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
                    <h2 className="h1 mb-0">{props.secTitle}</h2>
                </div>
                <table 
                className='table mb-0'>
<tbody>
<tr>
<th>{props.headLang.headDesg}</th>
<th>{props.headLang.headName}</th>
<th>{props.headLang.headConNo}</th>
</tr>
{props.MarketingData.map(function(data,index) {
  return(
    <tr key={index}>
<td >{data.designation}</td>
<td>{data.name}</td>
<td>{data.phoneno}</td>
</tr>
    
  )})}


</tbody>
</table>
                  </div>
  </div>
  )
}
