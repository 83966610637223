import React from 'react'
import { config } from '../config/rest'
import {Link} from 'react-router-dom'
import { Lang } from '../component/Language/Lang';
export const NewsBlog = (props) => {
   
  return (
    <section className="pt-0">
            <div className="container position-relative z-index-2">
                <div className="text-center mb-6 wow fadeIn title-style1" data-wow-delay="100ms">
                   
                    <h2 className="display-22 display-sm-18 display-md-16 mb-0">
                        {Lang[props.langData.lang].latneweveTitle}</h2>
                </div>
                <div className="row gx-xl-5 mt-n6">
               {/*{props.news.slice(0, 3).map(function(data,index) {*/}
               {props.news.sort((a, b) => b.attributes.date > a.attributes.date ? 1 : -1)
               .map(function(data,index) {
            return(
                    <div className="col-md-6 col-lg-4 mt-6 wow fadeIn" data-wow-delay="200ms" key={index}>
                        <article className="card card-style9">
                            <div className="card-body newsEv">
                                <span className="card-date">{data.attributes.date}</span>
                                  <h3 className="h4 mb-4" style={{minHeight:'35px'}}>
                                        <p href="#" dangerouslySetInnerHTML={{ __html: data.attributes.title }}></p>
                                    </h3>

                                {data.attributes.image.data && 
                                <div className="image-box">
                                    <img src={config.IMG_URL+data.attributes.image.data.attributes.url} 
                                    className="rounded" alt="..." 
                                    style={{minHeight: "240px", objectFit: "contain:"}}/>
                                </div>
                                }
                                <Link 
                                to="/NewsDetails/newsData" state={{newsDetails:data}}
                                 className="border-bottom display-30 font-weight-600 text-dark">Read More <i className="ti-arrow-right align-middle display-31"></i></Link>
                            </div>
                        </article>
                    </div>
            )})}
                   
                   
                </div>
            </div>

        </section>
  )
}
