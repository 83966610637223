import React ,{useEffect}from 'react'
import {Link} from 'react-router-dom'
import { useSelector,useDispatch } from "react-redux";
import { Lang } from './Language/Lang';


export default function Header(props) {
    const langData=useSelector(state=>state.cart);
    useEffect(() => {
        
     /* const rootHtml = document.getElementById("root-html");
       if (rootHtml) {
        rootHtml.setAttribute("dir", langData.lang==='ar' ? "rtl" : "ltr");
        rootHtml.setAttribute("lang", langData.lang ? "rtl" : "ltr");
      }*/
      },[]); 
    
  return (
    
    <header className="header-style1 menu_area-light"> 
    <div className="top-bar bg-secondary">
        <div className="container-fluid px-lg-1-6 px-xl-2-5 px-xxl-2-9">
            <div className="row">
                <div className="col-md-9 col-xs-9">
                    <div className="top-bar-info">
                        <ul className="ps-0">
                            <li><i className="ti-mobile"></i>{props.contactData && props.contactData.attributes.phOne}</li>
                            <li className="d-none d-sm-inline-block"><i className="ti-email"></i>{props.contactData && props.contactData.attributes.emailOne}</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 d-md-block col-xs-3">
                    <ul className={langData.lang==='ar'?'top-social-iconAr ps-0':'top-social-icon ps-0'} >
                        <li style={{color:'white',cursor:'pointer',paddingRight:20}}>
                        <span onClick={()=>props.changeLang('en')} className={langData.lang==='en'?'langChangeActive':'langChange'}>{Lang[langData.lang].menuen} </span> 
                        | 
                        <span onClick={()=>props.changeLang('ar')} className={langData.lang==='ar'?'langChangeActive':'langChange'}> {Lang[langData.lang].menuar}</span>
                        
                        </li>
                        {/*<li> <Link to="/">EN |</Link> <Link to="/">AR</Link></li>*/}
                        
                     </ul>
                </div>
               
            </div>
        </div>
    </div>
    <div className="navbar-default border-bottom border-color-light-white">

        
        <div className="" >
            <div className="row align-items-center">
                <div className="col-12 col-lg-12">
                    <div className="menu_area alt-font">
                        <nav className="navbar navbar-expand-lg navbar-light p-0">
                            <div className="navbar-header navbar-header-custom">
                                 <Link to="/" className="navbar-brand">
                                    <img id="logo" src="/assets/img/logos/logo-inner.png" 
                                    alt="logo"  />
                                </Link>
                                
                            </div>

                            <div className="navbar-toggler"></div>

                            <ul className="navbar-nav ms-1" id="nav" style={{display: 'none'}}>
                                <li><Link to="/"> {Lang[langData.lang].menuHome}</Link></li>
                                <li><a href="#">{Lang[langData.lang].menuAbout}</a>
                                            <ul>
                                                <li><Link to="/aboutUs/about">{Lang[langData.lang].submenuAbout}</Link></li>
                                                <li><Link to="/aboutUs/CEOmessage">{Lang[langData.lang].submenuAbtmsgCeo}</Link></li>
                                                <li><Link to="/aboutUs/team">{Lang[langData.lang].submentAbtTeams}</Link></li>
                                                <li><Link to="/aboutUs/financial">{Lang[langData.lang].submenutAbtFinan}</Link></li>
                                                <li><Link to="/aboutUs/tender">{Lang[langData.lang].submenutAbtTender}</Link></li>
  
                                            </ul>
                                        </li>
                                     <li></li>
                                 <li><a href="#">{Lang[langData.lang].menuProduct}</a>
                                            <ul>
                                                <li><Link to="/product/Private">{Lang[langData.lang].submenutPrdprivate}</Link></li>
                                                <li><Link to="/product/Commercial">{Lang[langData.lang].submenutPrdcommercial}</Link></li>
                                                <li><Link to="/product/Industrial">{Lang[langData.lang].submenutPrdindustrial}</Link></li>
                                                <li><Link to="/product/Liability">{Lang[langData.lang].submenutPrdlibility}</Link></li>
                                                <li><Link to="/product/Others">{Lang[langData.lang].submenutPrdOthers}</Link></li>
                                              
                                            </ul>
                                        </li>
                                     <li>
                                    
                                <a href="#">{Lang[langData.lang].menuOffice}</a>
                                            <ul>
                                                
                                                <li><Link to="/office/OurOffice">{Lang[langData.lang].menuOffice}</Link></li>
                                                <li><Link to="/office/GccLocation">{Lang[langData.lang].submenuofGcc}</Link></li>
                                                <li><Link to="/office/WorldWideLocation">{Lang[langData.lang].submenuOfWwl}</Link></li>
                                               {/*  <li><Link to="/office/OurBranch">{Lang[langData.lang].submenuOfourBranch}</Link></li>
                                               <li><Link to="/office/Branches">Our Branches</Link></li>*/}
                                               
                                              
                                            </ul>
                                    </li>
                                {/*<li><Link to="/news">{Lang[langData.lang].menuNews}</Link></li>*/}
                                <li><a href="#">{Lang[langData.lang].menuNews}</a>
                                            <ul>
                                                <li><Link to="/news">{Lang[langData.lang].menuNews}</Link></li>
                                                <li><Link to="/Gallery">{Lang[langData.lang].menuGallery}</Link></li>
                                                
                                            </ul>
                                        </li>
                                <li><Link to="/faq">{Lang[langData.lang].menuFaq}</Link></li>
                                <li><Link to="/contact">{Lang[langData.lang].menuContact}</Link></li>
                                <li><a href="#">{Lang[langData.lang].btnBuyonline}</a>
                                            <ul>
                                            <li><a  className="submenuMargidn" href="https://www.newindiaoman.com/directcustomer/MTRQUOTE.ASPX" target="_blank">{Lang[langData.lang].menubuyonlinesubmenumotorQuoteSubmenu}</a> </li>
                                            <li><a  className="submenuMargiddn" href="https://www.newindiaoman.com/directcustomer/RenewPolicy.aspx" target="_blank">{Lang[langData.lang].menubuyonlinesubmenumotorRenewSubmenu}</a> </li>
                                                
                                            <li><a href="https://www.newindiaoman.com/directcustomer/TravelPolicy.aspx" target="_blank">{Lang[langData.lang].menubuyonlinesubmenutravel}</a> </li>
                                            <li><a href="https://www.newindiaoman.com/directcustomer/PA.aspx" target="_blank">{Lang[langData.lang].menubuyonlinesubmenupaccident}</a> </li>
                                            <li><a href="https://www.newindiaoman.com/directcustomer/Compass.aspx" target="_blank">{Lang[langData.lang].menubuyonlinesubmenupcompass}</a> </li>
                                            <li><a href="https://www.newindiaoman.com/directcustomer/RetrieveQuote.aspx" target="_blank">{Lang[langData.lang].menubuyonlinesubmenuprquote}</a> </li>
                                            <li><a href="https://www.newindiaoman.com/directcustomer/PrintPolicy/PrintPolicy-Online.aspx" target="_blank">{Lang[langData.lang].menubuyonlinesubmenupolicySearch}</a> </li>

                                            </ul>
                                </li>

                                <li><a href="#">{Lang[langData.lang].btnClaim}</a>
                                            <ul>
                                            <li><a href="https://www.newindiaoman.com/directcustomer/claims/mtrClmIntimation.aspx" target="_blank">{Lang[langData.lang].menuclaimmotor}</a> </li>
                                                
                                            </ul>
                                </li>

                                <li><a href="https://www.newindiaoman.com/directcustomer/Complaints/Complaint.aspx" target="_blank">{Lang[langData.lang].btnGrivence}</a> </li>
                                
                            </ul>
                           <div className="attr-nav align-items-lg-center main-font">
                                <ul>
                                    {/* <li className=" d-xl-inline-block mr-5" >
                                         <a href= "https://www.newindiaoman.com/directcustomer/MTRQUOTE.ASPX" target="_blank"
                                         className="butn-style3 sm text-white"><span>{Lang[langData.lang].btnBuyonline}</span></a>
                                         </li>
                                       
                                        <li className=" d-xl-inline-block" > 
                                        <a href="https://www.newindiaoman.com/DirectCustomer/claims/mtrClmIntimation.aspx" target="_blank" 
                                            className="butn-style3 sm text-white" ><span>{Lang[langData.lang].btnClaim}</span></a>
                                        </li>*/}
                                         <li className=" d-xl-inline-block" > 
                                        <a href="https://www.newindiaoman.com/Account/login.aspx" target="_blank" 
                                            className="butn-style3 sm text-white" 
                                            style={{ background: '#eb6302'}}><span>{Lang[langData.lang].btnLogin}</span></a>
                                        </li>

                                </ul>
                            </div>
                           
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </header>
  )
}
