import React ,{useEffect,useState}from 'react';
import { BreadCumb } from '../component/BreadCumb'
import { Content } from './Contact/Content'
import { listHomecontacts, listHomecontactsar } from '../service/apiService';

import { useSelector,useDispatch } from "react-redux";
import { Lang } from '../component/Language/Lang';

export const Contact = () => {
  const [homeContact,sethomeContact]=useState([]);
  const langData=useSelector(state=>state.cart);

  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    })
    getHomeContact();
  },[langData]);

  const getHomeContact=async()=>{
    const homeContact=langData.lang==='ar'?await listHomecontactsar():await listHomecontacts();
    //console.log(homeContact.data.data);
    sethomeContact(homeContact.data.data);
  }

  return (
   <>
    <BreadCumb bgImage={"url('assets/img/banner/contact.jpeg')"} routeLink={"contact"} mainRoute={"Home"} 
    route={"Contact"} title={"Contact"}/>
   <Content address={homeContact} quickTitle={Lang[langData.lang].quikcont}
   btnSendmessage={Lang[langData.lang].btnsent} language={langData}/>
   </>
  )
}
