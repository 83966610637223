import React ,{useEffect,useState}from 'react';
import { BreadCumb } from '../component/BreadCumb'
import { Content } from './News/Content'
import { listHomeNews, listHomeNewsar, listNewsEventData } from '../service/apiService';
import { useSelector,useDispatch } from "react-redux";
import { Lang } from '../component/Language/Lang';

export const News = () => {
  const langData=useSelector(state=>state.cart);
  const [newsContact,setnewsContact]=useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    })
    const rootHtml = document.getElementById("root-html");
    if (rootHtml) {
     rootHtml.setAttribute("dir", langData.lang==='ar' ? "rtl" : "ltr");
     rootHtml.setAttribute("lang", langData.lang ? "rtl" : "ltr");
   }

   getNewsProduct(); 
   
   
  },[langData]);

  const getNewsProduct=async()=>{
    const newsData=langData.lang==='ar'? await listHomeNewsar() :await listHomeNews(); 
   // console.log(newsData);
    setnewsContact(newsData.data.data);
  }

  return (
   <>
     <BreadCumb bgImage={"url('assets/img/banner/newsevents.jpg')"} routeLink={"news"}
      mainRoute={Lang[langData.lang].homebread} 
    route={Lang[langData.lang].news}  title={Lang[langData.lang].newsblog} />
    <Content news={newsContact}/>  
   </>
  )
}
