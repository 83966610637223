import React,{useEffect,useState} from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import {Link} from 'react-router-dom'
import { useSelector,useDispatch } from "react-redux";
import { Lang } from './Language/Lang';
import { listHomeSlider, listHomeSliderar } from '../service/apiService';
import { config } from '../config/rest';
import LoaderComp from './Loader';

export const ResSlider = () => {
    const langData=useSelector(state=>state.cart);
    const [banner,setBanner]=useState([]); 
    useEffect(() => {
        getSlider();
      const rootHtml = document.getElementById("root-html");
       if (rootHtml) {
        rootHtml.setAttribute("dir", langData.lang==='ar' ? "rtl" : "ltr");
        rootHtml.setAttribute("lang", langData.lang ? "rtl" : "ltr");
      }
      },[langData]);

      const getSlider=async()=>{
        const resSlider=langData.lang==='ar'?await listHomeSliderar():await listHomeSlider();
        setBanner(resSlider.data.data);
      }
  return (
    <div style={{direction: 'initial'}}>
        {banner?<></>:<LoaderComp/>}
    <Carousel showThumbs={false} autoPlay showArrows={false} showStatus={false}>
    {banner.map(function(data,index) {
        return (
            <div className="item bg-img cover-background sliderTextShadow " 
            style={{ backgroundImage: "url("+config.IMG_URL+data.attributes.image.data.attributes.url+")" }} key={index}>
                <div className="container pt-sm-6 pt-md-0 d-flex flex-column">
                    <div className="row align-items-center justify-content-end text-end  pt-6 pt-md-0">
                        <div className="col-lg-10 mb-1-9 mb-lg-0 py-6">
                           {/*config.IMG_URL+data.attributes.image.data.attributes.formats.large.url*/}
                            <h1 className="display-18 display-sm-13 display-md-10 display-lg-5 display-xl-4 display-xl-3 mb-2-3 text-white"> {data.attributes.title}</h1>
                           <Link to="/product/Private" className="butn-style3 me-2"><span>{Lang[langData.lang].sliBtnlearn}</span></Link>
                           <Link to="/contact" className="butn-style3 white"><span>{Lang[langData.lang].slidBtnContx}</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        )})}
               
              
            </Carousel>
            </div>
  )
}
