import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import { useSelector,useDispatch } from "react-redux";
import { Lang } from './Language/Lang';

export default function Footer(props) {
    const langData=useSelector(state=>state.cart);
    useEffect(() => {
      const rootHtml = document.getElementById("root-html");
       if (rootHtml) {
        rootHtml.setAttribute("dir", langData.lang==='ar' ? "rtl" : "ltr");
        rootHtml.setAttribute("lang", langData.lang ? "rtl" : "ltr");
      }
      },[langData]);
  return (
    <footer className="footer-style3 pt-0 overflow-visible">
    <div className="container"> 
        <div className="row mt-n2-9">
            <div className="col-sm-6 col-lg-4 pe-5 mt-2-9 wow fadeIn" data-wow-delay="200ms">
                <div className="footer-top">
                    <h3 className="mb-1-9 h5 font16">{Lang[langData.lang].headAbtUs
                        }</h3>
                    <p className="mb-1-6 text-white font14">{/*Lang[langData.lang].ftabtTxt*/
                         props.abtData.attributes && props.abtData.attributes.aboutText.substring(0, 130) + "..."
                       
                        } <Link to="/aboutUs/about" style={{color:'white'}}>Read More</Link></p> 
                    <ul className="social-icon-style1 mb-0 d-inline-block list-unstyled">
                        <li className="d-inline-block me-2"><a href="#!"><i className="fab fa-facebook-f"></i></a></li>
                        <li className="d-inline-block me-2"><a href="#!"><i className="fab fa-twitter"></i></a></li>
                        <li className="d-inline-block me-2"><a href="#!"><i className="fab fa-youtube"></i></a></li>
                        <li className="d-inline-block"><a href="#!"><i className="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div>
            <div className="col-sm-6 col-lg-2 mt-2-9 wow fadeIn" data-wow-delay="350ms">
                <h3 className="h5 mb-1-9 font16">{Lang[langData.lang].headComp}</h3>
                <ul className="footer-list ps-0">
                    <li>
                    <Link to="/aboutUs/about"> {Lang[langData.lang].menuAbout}</Link>
                    </li>
                    <li>
                    <Link to="/faq"> {Lang[langData.lang].menuFaq}</Link>
                    </li>
                    <li>
                    <Link to="/contact"> {Lang[langData.lang].menuContact}</Link>
                    </li>
                   
                    </ul>
            </div>
            <div className="col-sm-6 col-lg-2 mt-2-9 wow fadeIn" data-wow-delay="350ms">
                <h3 className="h5 mb-1-9 font16">{Lang[langData.lang].headQuiclLink}</h3>
                <ul className="footer-list ps-0">
                    <li>
                    <a href="https://www.newindiaoman.com/Account/login.aspx" target="_blank" >{Lang[langData.lang].btnLogin}</a>
                    </li>
                    <li>
                    <a href= "https://www.newindiaoman.com/directcustomer/MTRQUOTE.ASPX" target="_blank">{Lang[langData.lang].btnBuyonline}</a> 
                    </li>
                    <li>
                    <Link to="/news"> {Lang[langData.lang].menuNews}</Link>
                    </li>
                    
                  </ul>  
            </div>
            <div className="col-sm-6 col-lg-3 mt-2-9 wow fadeIn" data-wow-delay="650ms">
                <h3 className="h5 mb-1-9 font16">{Lang[langData.lang].headNews}</h3>
                <p className="text-white">{Lang[langData.lang].ftsubTxt} </p>
                <form className="quform newsletter-form" action="#" method="post"
                 encType="multipart/form-data" >

                    <div className="quform-elements">

                        <div className="row">

                            <div className="col-md-12">
                                <div className="quform-element">
                                    <div className="quform-input">
                                        <input className="form-control" id="email_address" type="text" name="email_address" placeholder={Lang[langData.lang].ftSubPlacho}  />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="quform-submit-inner">
                                    <button className="butn-style3 white border-0 w-100" type="button"><span>{Lang[langData.lang].ftsubBtn}</span></button>
                                </div>
                                <div className="quform-loading-wrap text-center"><span className="quform-loading"></span></div>
                            </div>
                         </div>

                    </div>

                </form>
            </div>
        </div>
    </div>
    <div className="footer-bar">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <p>&copy; <span className="current-year"></span> New India Oman is Powered by  <Link to="/" className=" text-white-hover">InteracOman</Link></p>
                </div>
            </div>
        </div>
    </div>
</footer>

  )
}
