import React from 'react'
import { Lang } from '../../component/Language/Lang';

export const Content = (props) => {
   // console.log(props.addData);
  return (
    <section className="pt-0">
    <div className="container">
        <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
            <span>{Lang[props.langD].menuFaq}</span>
            <h2 className="display-22 display-sm-18 display-md-16 display-lg-11 mb-0">
            {Lang[props.langD].faqdtls}</h2>
        </div>
        <div className="row wow fadeIn position-relative z-index-1" data-wow-delay="200ms">
            <div className="col-lg-6">
                <div id="accordion" className="accordion-style style1">
                {props.faqData.map(function(data,index) {
                    return(
                    <div className="card mb-3" key={index}>
                        <div className="card-header" id={"headingOne"+data.id+""}>
                            <h5 className="mb-0">
                                {index==0?
                                <button className="btn btn-link " data-bs-toggle="collapse" 
                                data-bs-target={"#collapseOne"+data.id+""} aria-expanded="true" 
                                aria-controls={"#collapseOne"+data.id+""}>{data.attributes.faqTitle}
                                </button>:
                                 <button className="btn btn-link collapsed" data-bs-toggle="collapse" 
                                 data-bs-target={"#collapseOne"+data.id+""} aria-expanded="true" 
                                 aria-controls={"#collapseOne"+data.id+""}>{data.attributes.faqTitle}
                                 </button>
                                }
                            </h5>
                        </div>
                        {index==0?
                        <div id={"collapseOne"+data.id+""} className="collapse show" 
                        aria-labelledby={"headingOne"+data.id+""} data-bs-parent="#accordion">
                            <div className="card-body defaultfontandspacewithFaq" 
                             dangerouslySetInnerHTML={{ __html: data.attributes.faqDetails }}>
                           
                            </div>
                        </div>:
                         <div id={"collapseOne"+data.id+""} className="collapse " 
                         aria-labelledby={"headingOne"+data.id+""} data-bs-parent="#accordion">
                             <div className="card-body defaultfontandspacewithFaq"
                              dangerouslySetInnerHTML={{ __html: data.attributes.faqDetails }}>
                            
                             </div>
                         </div>}
                    </div>
                    )})}
                    
                 </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
                <div className="ps-lg-1-9 image-hover">
                    <img src="assets/img/content/faq.jpg" className="rounded" alt="..."/>
                </div>
                <div className="ps-lg-1-9 ">
                {props.ceoData.map(function(data,index) {
                    return(
                <p key={index}>
                <b>{data.attributes.Name}</b><br/>
{data.attributes.Designation} <br/>
{data.attributes.Company}<br/>
                        </p>
                    )})}
                </div >
               
                <div className="card card-style3 mt-1-9">
                                <div className="card-body p-1-6 p-sm-1-9">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 icon-box">
                                            <i className="ti-location-pin text-primary z-index-9 display-8 position-relative"></i>
                                            <div className="box-circle primary"></div>
                                        </div>
                                        {props.addData.map(function(data,index) {
                                            return(
                                        <div className="flex-grow-1 ms-3">
                                            <h4 className="h5"></h4>
                                            <span  dangerouslySetInnerHTML={{ __html: data.attributes.Address }}/>
                                            <span>
                                            Phone: {data.attributes.Phone}<br/>
                                            Fax: {data.attributes.fax}<br/>
                                            E-mail: {data.attributes.Email}<br/>
                                            Web: {data.attributes.website }<br/>
                                            </span>
                                        </div>
                                            )})}
                                    </div>
                                </div>

                                
                            </div>

                            
            </div>
            
        </div>
    </div>
    {/*<img src="assets/img/content/shape6.png" className="position-absolute top-0 ani-left-right right-5 d-none d-md-block" alt="..."/>
    <img src="assets/img/content/shape1.png" className="position-absolute bottom-0 start-0 opacity1 ani-top-bottom" alt="..."/>
*/}
    </section>
  )
}

